html {
  @apply text-th-text;
}

.dashboard-container {
  @apply bg-th-background;
}

.dashboard,
.dashboard-container {
  @apply flex flex-wrap
    min-h-screen;
}

.dashboard {
  @apply flex-col flex-1 
    lg:min-w-min md:ml-24 lg:ml-72
    box-border 
    md:pt-3 md:pb-20 md:px-11 
    pt-3 pb-12 px-8 
    bg-transparent
    w-full;
  max-width: 120rem;
  overflow-x: auto;
  & > * {
    @apply grid
  }

  &.expanded {
    @apply lg:ml-24;
  }
}

.sidebar {
  @apply absolute top-0 bottom-0 left-0 z-10;

  & > * {
    @apply fixed overflow-y-auto overflow-x-hidden top-0 bottom-0 left-0;
  }
}

.sidebar-container,
.sidebar-action {
  @apply flex items-center flex-col justify-between
    outline-none;
}
.sidebar-container {
  @apply absolute md:static 
    md:left-auto 
    min-h-screen h-auto 
    bg-white 
    w-24 lg:min-w-min lg:w-72
    px-6 pt-5 lg:pt-0
    shadow-darker 
    transform duration-150;

  &.d-mobile {
    @apply duration-200 
        static
        left-auto
  }

  &.collapsed {
    @apply lg:w-24;

    .sidebar-action {
      @apply lg:rounded-full lg:w-max flex lg:justify-center;
    }
    .tooltip {
      @apply lg:hidden;
    }
  }
}

.sidebar-action {
  @apply transition duration-150 ease-linear 
    cursor-pointer 
    relative
    rounded-full lg:rounded-md
    w-max lg:w-full 
    p-2 
    my-1 mx-0 md:mx-auto lg:mx-0
    hover:bg-gray-300
    flex justify-center lg:flex-row-reverse lg:justify-end;

  &.active-link {
    @apply text-white 
      bg-th-primary;

    .mat-icon,
    .tooltip {
      @apply text-white;
    }
  }

  .mat-icon {
    @apply h-8 
      w-8 
      text-2xl 
      flex items-center;
  }

  &:hover {
    .tooltip {
      @apply left-2/4 lg:left-auto
        opacity-100
        transition duration-150
        h-auto
        px-3 py-1 lg:p-0;
    }
  }
  .tooltip {
    @apply hidden
      lg:block 
      left-2/4 lg:left-auto 
      bg-th-primary lg:bg-transparent
      text-white lg:text-gray-700 text-sm text-center lg:text-left
      opacity-0 lg:opacity-100
      max-w-max
      h-auto 
      p-0 ml-3
      rounded-md;
  }
}
.sidebar-toggle {
  @apply shadow md:hidden fixed top-3 right-3 z-10 rounded-full bg-white p-3;
}

.sidebar-section {
  @apply w-auto lg:w-full
    pb-5 lg:pb-0 
    flex flex-col;
}

.header-logo img {
  @apply w-20 
    h-auto;
}

.header-action {
  @apply flex self-center items-center;
}

.sidebar-overlay {
  @apply h-screen w-screen 
    md:hidden
    bg-black bg-opacity-60 
    fixed left-0 top-0 
    z-0;
}

.main {
  @apply h-screen 
    flex 
    overflow-hidden;
}

.content {
  @apply flex flex-col flex-1 
    overflow-auto;
}

.content-centered {
  @apply flex flex-col items-center justify-center flex-grow 
    m-0 p-0;
}
