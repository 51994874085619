.mat-icon {
  @apply w-auto
    leading-6;
  table &,
  .dropdown-item & {
    @apply text-lg;
  }
}

.clickable-card {
  @apply cursor-pointer;
}

.clickable-card,
.card-block,
.card {
  @apply py-4 px-4
    rounded
    bg-white
    transform duration-150
    select-none
    relative;
}

.card-block {
  @apply block
    flex-1;
}

.card {
  @apply p-4;
}

.button {
  @apply shadow-md transition duration-150 ease-in-out;
  &:active {
    @apply shadow-none transform translate-x-1 translate-y-1;
  }
  &--secondary {
    @apply button border border-gray-700 rounded px-4 py-2 uppercase;
  }
  &--primary {
    @apply button bg-th-primary text-th-white border-none rounded-lg text-xl py-2 px-8;
  }
  &--create {
    @apply button bg-th-create text-th-white border-none rounded-lg text-xl py-2 px-8;
  }

  &-sm {
    @apply button h-12;
  }
  &-xl {
    @apply button h-12 text-base px-14;
  }
  &-xs {
    @apply button text-xs h-8 leading-8 py-0 px-3;
  }
}

.button.clear {
  @apply bg-transparent border;
}

.button.delete {
  @apply text-white
    bg-red-600;
}

.back-button {
  @apply bg-transparent
    border border-gray-500
    h-10;
}
.submit-container {
  .back-button {
    @apply h-14;
  }
}

.button.hidden {
  @apply h-0
    overflow-hidden
    m-0;
}

.clickable-card:active.shadow,
.button:active.shadow {
  @apply shadow-none
    transform translate-x-1 translate-y-1;
}

.checkbox-card {
  @apply my-4 mr-4 ml-0;
}

.purple-card {
  @apply text-white;
}

.checkbox-card.checked {
  @apply text-white
    font-bold;
}

.checkbox-container.disabled {
  .checkbox::before,
  .radio::before {
    @apply bg-gray-300
      border-gray-300;
  }
  .input-card:not(.checked) {
    @apply bg-gray-200;
  }
}

.shadow,
.shadow::before {
  box-shadow: 4px 4px 4px rgba(10, 10, 10, 0.2) !important;
}

.input-card {
  @extend .card;
  @apply block bg-th-label;

  @apply flex-1;

  &[disabled] {
    @apply shadow-none
      text-gray-500
      bg-gray-600;
  }

  &:not(.clickable-card) {
    @apply transition-none;
  }
}

.tag-container {
  @apply
    w-full
    flex flex-wrap flex-row
    mb-3 mx-0
    p-3
    transition duration-200 ease-linear;
  flex: 1 0 100%;
  &--active {
    @apply
      p-5
    bg-th-label
      rounded-lg;
  }
}

.tag {
  @apply my-1 mr-2 ml-0
    bg-gray-500
    text-white
    py-1 px-3
    rounded-3xl
    text-sm
    min-w-min
    text-center
    self-baseline
    flex items-center;

  .remove-tag-button,
  .remove-date-button {
    @apply
      cursor-pointer
      text-xs
      font-bold
      p-0
      my-0 mr-0 m-1
      text-gray-700
      bg-white
      rounded-full
      text-center
      w-4
      h-4
      flex justify-center items-center self-center;
  }
}

.group {
  @apply relative mt-10 px-5 pt-10 pb-5 transition duration-300 border border-gray-300;
}

.group .group-name {
  @apply cursor-pointer select-none absolute -top-5 -left-px bg-gray-300 py-3 px-5 border font-bold;

  &.inline {
    @apply flex items-center;

    h2 {
      @apply m-0;
    }
  }
}

.panel-closed {
  @apply h-10 border-0 py-0;
}

.show-button {
  @apply font-light text-xs uppercase;
}

.srow {
  @apply flex-wrap;
}

.srow dbce-form-read-text {
  @apply whitespace-nowrap flex flex-grow flex-shrink-0;
  flex-basis: 20%;
}

.onethird-container {
  .onethird-content,
  dbce-form-bool,
  dbce-form-text {
    flex: 1 0 20%;
  }
}

.patient-info {
  @apply w-max mr-5;
}

.patient-group-info {
  @apply flex flex-col;
}

.detail-info {
  @apply flex flex-col h-full;
}

.sort-toggle {
  @apply flex items-center justify-between cursor-pointer;

  .thead-dark & .material-icons {
    @apply text-white;
  }
}

.backdrop {
  @apply fixed top-0 left-0 bg-black bg-opacity-75 z-50 w-screen h-screen;
}

.alert-box {
  @apply fixed p-4 bg-white rounded;
  top: 30vh;
  left: 20vw;
  width: 60vw;
  z-index: 100;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.alert-box-actions {
  @apply text-right;
}

.list-group {
  @apply border-t border-gray-200;

  &-item {
    @apply relative
      block
      py-3 px-6;

    &:not(:last-child) {
      @apply border-b border-gray-200;
    }
  }
}

.CustomPager {
  @apply
  flex justify-between flex-wrap items-center;

  .pagination {
    @apply flex items-center gap-2;

    .page-item {
      &.pagination-page{
        .page-link{
          @apply px-4 rounded-md m-0 hover:bg-gray-300 hover:text-gray-600;
        }
      }
      .page-link {
        @apply
          py-2
          mx-2
          text-gray-600;
      }
      &.active .page-link {
        @apply text-white
            font-bold
            bg-th-primary;
      }

      &.pagination-last, &.pagination-first {
        @apply
          text-lg
          font-bold;
      }

      &.disabled {
        @apply
          hidden;
      }

      .material-icons {
        @apply
          text-center
          text-th-primary;
      }
    }
  }

  .dropup .dropdown-toggle::after {
    @apply
      hidden;
  }
}

.dropdown-item:focus > .material-icons {
  @apply
    text-white;
}

.AliquotForm,
.PaycheckCardForm,
.PaycheckSummaryCard {
  .form-input {
    @apply py-2 px-4 text-sm;
  }
  .material-icons {
    @apply text-sm;
  }
  .mat-icon {
    @apply h-4 w-4;
  }
}

.PaycheckCardForm__container {
  &--col > * {
    &:last-child {
      @apply lg:mb-0;
    }
  }

  .card-block {
    .ng-invalid:hover:not(:focus) + .fieldErrors {
      @apply hidden;
    }
    .fieldErrors {
      @apply max-w-xs;
      width: fit-content;
    }
    &:focus-within {
      @apply relative z-10 transform -translate-x-2 -translate-y-2;
      box-shadow: 8px 8px 4px rgba(0, 0, 0, 0.26)!important;
    }
  }
}

.PaycheckCardForm {
  @apply text-sm xl:text-xs 2xl:text-sm;
  input[type=text], input[type=number] {
    @apply text-sm xl:text-xs 2xl:text-sm px-2 py-1;
  }
  input.ng-dirty:not(.ng-invalid) {
    @apply ring-2 ring-yellow-600 bg-yellow-100;
  }
}

@mixin active-col-bg($col-number) {
  .grid :nth-child(#{$col-number}) {
    @apply bg-green-200 mr-2;
  }
  .grid:first-child :nth-child(#{$col-number}) {
    @apply rounded-t-xl;
  }
  .grid:last-child :nth-child(#{$col-number}) {
    @apply rounded-b-xl;
  }
}

.PaycheckSummaryCard {
  @apply text-sm;
  .grid div {
    @apply px-2 py-1;

    &:not(:first-child) {
      @apply pl-8 transition-all duration-200;
    }
  }
  &.active-col-1 {
    @include active-col-bg(1);
  }
  &.active-col-2 {
    @include active-col-bg(2);
  }
  &.active-col-3 {
    @include active-col-bg(3);
  }
}

.PaycheckPropInfo {
  @apply max-w-max z-10;

  table tbody tr:last-child {
    td, th {
      @apply pb-2;
    }
  }
}

.material-icons.mat-icon {
  @apply flex items-center justify-center text-gray-700;

  &.success {
    @apply text-green-400 font-bold;
  }
  &.danger {
    @apply text-yellow-400;
  }
  &.fail {
    @apply text-red-400 font-bold;
  }
}

.dropdown-item .material-icons {
  @apply flex justify-center items-center;

  &.mat-icon {
    @apply w-6 h-auto;
  }
}

.dropdown-menu {
    @apply
      shadow;
}

.mat-button-wrapper {
  @apply flex items-center justify-center;
}

.scrolltop-button {
  .mat-icon.material-icons {
    @apply text-white;
  }
  @apply bg-th-primary text-white;
}

.PaycheckAssociateListNav {
  &__next,
  &__prev {
    @apply p-3 flex items-center justify-center opacity-80 hover:opacity-100 bg-th-primary text-white rounded-full cursor-pointer
    fixed top-2/4 transform -translate-y-2/4 ;

    .material-icons {
      @apply text-white text-2xl;
    }
  }

  &__next {
    @apply right-1;
  }
  &__prev {
    @apply -ml-8 md:-ml-10;
  }
}