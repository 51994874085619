.table {
    @apply
        w-full;
    
    thead {
        @apply
            block
            mb-5
            w-full;
        tr {
            @apply 
                bg-gray-100;
        }
    }

    tbody {
        @apply
            block 
            w-full;

        tr {
            @apply
                bg-white my-2;
        }
    }

    th, td {
        @apply
            text-left
            text-sm
            font-normal
            flex-grow px-2;
    }
    tr {
        @apply
        flex justify-between p-4 rounded-xl items-center;
    }

    .actions, .icon {
        @apply
            flex-grow-0
            w-20
            text-center;
        & > * {
            @apply align-middle;
        }
    }
}

.table-responsive {
    @apply
        overflow-x-auto lg:overflow-visible;
    
    thead > tr {
        @apply
            shadow-none lg:shadow;
    }
}