@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

// bootstrap default
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/spinners";

// Tailwind
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer utilities {
  @variants responsive {
      .masonry-3-col {
          column-count: 3;
          column-gap: 1em;
      }
      .masonry-2-col {
          column-count: 2;
          column-gap: 1em;
      }
      .break-inside {
          break-inside: avoid;
      }
  }
}

// libs
@import '~ngx-toastr/toastr';
@import "~@fortawesome/fontawesome-free/css/all.css";

// @import "./styles/theme";
@import './styles/pages';
@import './styles/form';
@import './styles/objects';
@import './styles/tables';