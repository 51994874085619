.form-input {
  @apply flex flex-col box-border;
}

input.form-input,
select.form-input {
  @apply 
    transition duration-300 ease-in-out 
    block 
    rounded 
    p-4
    bg-th-label focus:bg-th-white 
    focus:ring-2 focus:ring-gray-300 outline-none;

  &[disabled] {
    @apply
      bg-gray-300 
      text-gray-500
      cursor-not-allowed;
  }
}

.form-group-title {
  @apply text-3xl mt-8 mb-5;
}

.date-container {
  @apply
    relative;
}

.date-picker {
  @apply
    rounded-none
    absolute
    top-0
    bottom-0
    right-0;
}

.checkbox-container,
.gender-selection-container {
  @apply
    flex
    flex-1
    items-start
    justify-start
    mt-1;
}

.fieldErrors {
  @apply hidden absolute text-white bg-red-600 py-1 px-2 text-sm transform -translate-x-0.5 translate-y-1 rounded z-10;
}

.ng-submitted, 
.submitted,
.validation-ready,
.search-form {
  input.ng-invalid,
  select.ng-invalid,
  .custom-control-wrapper.ng-invalid input.custom-control {
    @apply ring-2 ring-red-600 bg-red-100;

    &:focus + .fieldErrors,
    &:hover + .fieldErrors {
      @apply block;
    }
  }
  .custom-control-wrapper.ng-invalid {
    &:focus + .fieldErrors,
    &:hover + .fieldErrors {
      @apply block;
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    &.ng-invalid::before {
      @apply ring-2 ring-red-600 bg-red-100;
    }
  }

  .invalid-feedback {
    @apply
      block
      text-red-600 text-sm;
  }
}

.form-label {
  @apply truncate text-base;

  .material-icons {
    @apply text-base cursor-pointer;

    &.fa-asterisk {
      @apply text-xs;
    }
  }
}

input[type="checkbox"], 
input[type="radio"] {
    @apply
      appearance-none
      h-14
      w-14
      mr-5
      mt-0
      rounded bg-th-label;
      &::before {
        content: '';
        @apply
          invisible
          block
          transition duration-150
          w-14
          h-14
          cursor-pointer
          border-8 border-white;
      }

    &:checked{
      @apply
        ring-2 ring-gray-300 outline-none;
      &::before {
      @apply
        visible
        bg-th-primary;
      }
    }
}

input[type='number']::-webkit-inner-spin-button, 
input[type='number']::-webkit-outer-spin-button { 
  @apply hidden;
}

.radio-label {
  @apply flex items-center mr-10;
}
input[type="radio"] {
  @apply mr-4;

  &::before {
    @apply rounded-full;
  }
}

.search-input {
  @apply relative;

  input {
    @apply cursor-pointer pr-12
  }
  .material-icons {
    @apply absolute right-3 top-1/2 transform -translate-y-2/4;
  }  
}

.search-form {
  input {
    @apply
      bg-white
      shadow-md;

    &:focus {
      @apply
        shadow-none
        ring-gray-300;
    }
  }
}

button:disabled {
  @apply opacity-60 cursor-not-allowed;
}